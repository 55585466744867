<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="ime"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import router from "../router";
export default {
  data() {
    return {
      search: ""
    };
  },
  computed: {
    items() {
      if (!this.$store.getters.resultItems) {
        router.push("/");
      }
      return this.$store.getters.resultItems;
    },
    headers() {
      return [
        { text: "Код", value: "Kod" },
        { text: "Наименование", value: "Ime", divider: true },
        { text: "Количество", value: "nal", align: "end" },
        //{ text: "", value: "s", align: "end" },
        { text: "Ед. Цена", value: "ed", align: "end" }
      ];
    }
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" && value.search(new RegExp(search,"i")) !== -1
        /*
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
          */
      );
    }
  }
};
</script>

<style scoped></style>
