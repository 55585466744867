import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import Login from "../views/Login.vue";
import Result from "../views/SearchResult.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Search
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  },
  {
    path: "/result",
    name: "result",
    component: Result
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  //mode: "history",
  routes
});

export default router;

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  if (authRequired && !loggedIn) {
    return next("/login");
  }
  next();
});
