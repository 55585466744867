import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import "./registerServiceWorker";
import api from "./services/api";

Vue.config.productionTip = false;
Vue.prototype.$http = api;
api.defaults.timeout = 10000;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
  //components: {App},
}).$mount("#app");

api.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    store.commit("LOAD_START");
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    store.commit("LOAD_END");
    return response;
  },
  error => {
    store.commit("LOAD_END");
    if (!error.response) {
      store.dispatch("myalert", {
        text: "No connection to server.",
        timeout: 5000
      });
    } else if (error.response.status) {
      let status = error.response.status;
      if (status === 400) {
        if (error.response.data) {
          let data = error.response.data;
          let msg = data.Message ?? "Bad request";
          store.dispatch("myalert", {
            text: msg,
            timeout: 3000
          });
        }
      } else if (status === 401) {
        store.dispatch("myalert", {
          text: "Unauthorized",
          timeout: 3000
        });
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);
