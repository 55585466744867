<template>
  <div>
    <v-app-bar app color="primary" dark dense :value="isLoggedIn">
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-btn icon :to="{ path: 'search' }">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>      
      <v-spacer></v-spacer>

      <v-btn icon  @click="logout">
        <v-icon>mdi-export</v-icon>
      </v-btn>      
    </v-app-bar>

  </div>
</template>

<script>
//import func from '../../vue-temp/vue-editor-bridge';
export default {
  name: "navbar",
  data: () => ({
    drawer: false,
    group: null
  }),
  computed: {
    isLoggedIn: function() {
      //console.log(`NavBar isLoggedIn: ${this.$store.getters.isLoggedIn}`);
      return this.$store.state.loggedIn;
    }
  },

  methods: {
    logout: function() {
      this.$store.dispatch("logout");
    },
  }
};
</script>

<style lang="scss" scoped></style>
