<template>
  <v-app>
    <NavBar></NavBar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <SnackbarStatus></SnackbarStatus>
    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
    An update is available
      <v-btn text @click="refreshApp">Update</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import SnackbarStatus from "./components/SnackbarStatus.vue";
import update from './mixins/update';

export default {
  name: "App",

  components: {
    NavBar,
    SnackbarStatus
  },

  data: () => ({
    drawer: false,
    group: null
  }),
  mixins: [update],
};
</script>
