<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" lg="4" md="6" sm="8" >
        
          <v-form v-model="valid" @submit.prevent="submit">
            <v-text-field
              v-model="searchString"
              value=""
              :rules="[rules.required, rules.min]"
              placeholder="търсене"
            >
            </v-text-field>
            <v-layout row justify-center class="pt-4">
              <v-btn
                type="submit"
                x-large
                color="primary"
                :disabled="!valid"
                :loading="loading"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-layout>
            <v-layout row justify-center class="pt-6">
              <v-btn-toggle v-model="field" group color="primary" dense>
                <v-btn>по име</v-btn>
                <v-btn>по код</v-btn>
              </v-btn-toggle>
            </v-layout>
            <v-layout row justify-center>
              <v-btn-toggle v-model="toggle" group dense color="primary">
                <v-btn><v-icon>mdi-contain-start</v-icon></v-btn>
                <v-btn><v-icon>mdi-contain</v-icon></v-btn>
                <v-btn><v-icon>mdi-contain-end</v-icon></v-btn>
              </v-btn-toggle>
            </v-layout>
          </v-form>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      row: null,
      toggle: 0,
      searchString: "",
      field: 0,
      rules: {
        required: value => !!value || "Required",
        min: value => value.length >= 1 || "Min 3 characters"
      }
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  methods: {
    submit() {
      this.$store.dispatch("search", {
        SearchString: this.searchString,
        Contain: this.toggle,
        Searchfield: this.field
      });
    }
  }
};
</script>

<style scoped></style>
