import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import api from "./services/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    loggedIn: localStorage.getItem("token") ? true : false,
    SnackStatus: {
      visible: false,
      color: "error",
      timeout: 3000,
      text: "Some text"
    },
    searchResult: null
  },
  getters: {
    resultItems: state => state.searchResult
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      localStorage.setItem("token", userData.Token);
      state.loggedIn = true;
    },
    LOGOUT() {
      localStorage.removeItem("token");
      location.reload();
    },
    SNACKBAR(state, Data) {
      Object.assign(state.SnackStatus, Data);
      state.SnackStatus.visible = true;
    },
    SEARCH_DONE(state, data) {
      state.searchResult = data;
      router.push("/result");
    },
    LOAD_START(state) {
      state.loading = true;
    },
    LOAD_END(state) {
      state.loading = false;
    }
  },
  actions: {
    login({ commit }, credentials) {
      return api.post("token", credentials).then(({ data }) => {
        commit("SET_USER_DATA", data);
      });
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
    myalert({ commit }, Data) {
      commit("SNACKBAR", Data);
    },
    search({ commit }, data) {
      return api.post("search", data).then(({ data }) => {
        commit("SEARCH_DONE", data);
      });
    }
  }
});
