<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="10" xl="4" lg="4" md="5" sm="6">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login form</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" @submit.prevent="login">
              <v-text-field
                v-model="username"
                label="Username"
                name="username"
                type="text"
                outlined
                value=""
                autocomplete="username"
                :rules="[rules.required, rules.minUsername]"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.minPassword]"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                label="Password"
                hint="At least 8 characters"
                value=""
                class="input-group--focused"
                outlined
                autocomplete="current-password"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :loading="loading"
                :block="true"
                :disabled="!valid"
                type="submit"
              >
                <v-icon left>mdi-login-variant</v-icon>
                Login
              </v-btn>
            </v-form>
          </v-card-text>
          <v-card-actions> </v-card-actions>
          <v-alert
            dense
            text
            type="error"
            transition="scale-transition"
            v-if="status"
            >Login incorrect</v-alert
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      valid: false,
      showPassword: false,
      username: "",
      password: "",
      status: null,
      rules: {
        required: value => !!value || "Required.",
        minPassword: v => v.length >= 8 || "Min 8 characters",
        minUsername: v => v.length >= 5 || "Min 5 characters"
      }
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password
        })
        .then(() => {
          //console.log(`Login: ${localStorage.getItem('user')}`);
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          this.status = err.response?.status ?? null;
        });
    }
  }
};
</script>
